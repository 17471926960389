<template>
  <div>
    <title-nav :title="'강좌관리'" :nav="'강좌관리 / 강좌현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <lecture-select
            :isSearch="true"
            @updateOpt1="updateOpt1"
            @updateOpt2="updateOpt2"
            @updateOpt3="updateOpt3"
            @updateLcNo="updateLcNo"
            :selectedCnt="selectedCnt"
          />
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center table-head-fixed"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
            style="font-size:.8rem"
          >
            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>
            <template v-slot:cell(title)="data">
              <b-button variant="link" @click="edit(data)">{{ data.value }}</b-button>
            </template>
            <template v-slot:cell(is_close)="data">
              <span v-if="data.value == 'Y'"
                ><b-icon icon="toggle2-on" scale="1.5" variant="success"></b-icon
              ></span>
              <span v-else
                ><b-icon icon="toggle2-off" scale="1.5" variant="secondary"></b-icon
              ></span>
            </template>
            <template v-slot:cell(is_cost)="data">
              <span v-if="data.value == 'Y'"
                ><b-icon icon="toggle2-on" scale="1.5" variant="info"></b-icon
              ></span>
              <span v-else
                ><b-icon icon="toggle2-off" scale="1.5" variant="secondary"></b-icon
              ></span>
            </template>

            <template v-slot:head(play_order)>
              <span v-b-tooltip.hover.top="'정렬원칙-  1. PlayNo, 2.강좌No.'" variant="primary"
                >PlayNo.<b-icon icon="question-circle"
              /></span>
            </template>

            <!-- PlayNo 변경 -->
            <template v-slot:cell(play_order)="data">
              <b-button class="link" @click="showModal(data, 'modal-order')" size="md">
                {{ data.value }}</b-button
              >
            </template>
            <template v-slot:cell(delete)="data">
              <b-button
                variant="secondary"
                size="sm"
                @click="showModal(data, 'modal-delete')"
                class="text-nowrap"
                >삭제</b-icon
              ></b-button>
            </template>
            <!-- PlayNo 변경 -->
            <!-- <template v-slot:cell(file_path)="data">
            <span>
              <a :href="data.value + '?play=' + vKey" target="_blank">
                {{ data.value }}?play={{ vKey }}
              </a>
            </span>
          </template> -->
          </b-table>
        </div>
      </div>
    </b-container>
    <template>
      <b-modal size="sm" ref="modal-order" hide-footer centered title="Play No. 수정">
        <b-row>
          <b-col>
            <div>현재 Play No : {{ currentNo }}</div>
          </b-col>
        </b-row>

        <b-row align-v="center">
          <b-col cols="4">
            바꿀 순서 :
          </b-col>
          <b-col>
            <b-form-input type="number" size="sm" v-model="next_index" min="0" step="1" />
          </b-col>
        </b-row>

        <br />
        <b-button class="m-1" @click="changeOrder" variant="danger">변경</b-button>
        <b-button class="m-1" @click="closeModal('modal-order')">닫기</b-button>
      </b-modal>
    </template>
    <template>
      <b-modal
        ref="modal-delete"
        v-model="modalDeleteVod"
        size="sm"
        hide-footer
        centered
        title="정말 삭제하시겠습니까? 파일이 삭제됩니다."
        button-size="sm"
      >
        <p>
          <b-row>
            <b-col>강좌 번호 :</b-col>
            <b-col>{{ this.selected_lc_no }}</b-col>
          </b-row>
        </p>
        <b-row>
          <b-col>
            <b-button class="m-1" variant="danger" @click="removeLecture()">삭제</b-button>
            <b-button class="m-1" @click="closeModal('modal-delete')">닫기</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { deleteLecture, getLectureList, updateLectureOrder } from '../../api/lecture'
import LectureSelect from '../util/LectureSelect'
export default {
  name: 'LectureIndex',
  components: { LectureSelect },
  data() {
    return {
      next_index: '',
      next_index_list: [],
      opt1: '',
      opt2: '',
      opt3: '',
      opt4: '',
      items: [],
      isBusy: false,
      headVariant: 'dark',
      striped: true,
      bordered: false,
      borderless: false,
      hover: true,
      dark: false,
      noCollapse: false,
      fixed: false,
      currentNo: 0,
      selected_row: '',
      current_condition: '',
      categoryList: [],
      selected_lc_no: '',
      modalDeleteVod: false,
      vKey: '',
      selectedCnt:0,
      fields: [
        {
          key: 'reg_dt',
          label: '등록일시',
          colType: 'span',
          tdClass: 'w5'
        },
        {
          key: 'cate1',
          label: '대',
          colType: 'span',
          tdClass: 'text-left w5'
        },
        {
          key: 'cate2',
          label: '중',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'cate3',
          label: '소',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'play_order',
          label: 'PlayNo.',
          colType: 'span'
        },
        {
          key: 'lc_no',
          label: '강좌 No.',
          colType: 'span'
        },
        {
          key: 'title',
          label: '강좌제목',
          colType: 'span',
          tdClass: 'text-left'
        },
        // {
        //   key: 'file_path',
        //   label: '등록URL',
        //   colType: 'span',
        //   tdClass: 'w10',
        //   tdClass: 'text-left'
        // },
        {
          key: 'is_close',
          label: '숨김여부',
          colType: 'span'
        },

        {
          key: 'is_cost',
          label: '유료여부',
          colType: 'span'
        },
        {
          key: 'delete',
          label: '삭제',
          colType: 'button',
          tdClass: 'w5'
        }
      ]
    }
  },
  computed: {},

  methods: {
    edit(data) {
      //  this.$router.push({name: 'Query', query: {name: 'cat', age: 3}})
      this.$router.push({ path: '/lecture/edit', query: { lc_no: data.item.lc_no } })
    },
    async changeOrder() {
      var lc_no = this.selected_row.item.lc_no
      if (this.next_index >= 0 && Number(this.next_index)) {
        const resp = await updateLectureOrder(lc_no, this.next_index)
        if (resp.code !== '0000') {
          alert(resp.message)
        } else {
          //reload data
          this.items = []
          getLectureList(this.current_condition).then(_resp => {
            this.items = _resp.data.list
          })
          this.closeModal('modal-order')
        }
      } else {
        alert('0이상 값을 입력하세요')
      }
    },
    closeModal(modalRef) {
      if (modalRef == 'modal-order') {
        this.next_index = ''
        this.$refs['modal-order'].hide()
      } else {
        this.$refs['modal-delete'].hide()
      }
    },
    async showModal(item, modalRef) {
      if (modalRef == 'modal-order') {
        this.selected_row = item
        this.currentNo = item.value
        this.$refs['modal-order'].show()
      } else {
        this.selected_lc_no = item.item.lc_no
        console.log(this.selected_lc_no)
        this.$refs['modal-delete'].show()
      }
    },
    updateOpt1(data) {
      this.opt1 = data.id
      var params = `cate1=${this.opt1}`
      this.current_condition = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      getLectureList(params).then(_resp => {
        this.items = _resp.data.list
       this.selectedCnt = this.items.length
      })
    },
    updateOpt2(data) {
      if(data == '') return
      this.opt2 = data.id
      var params = `cate1=${this.opt1}&cate2=${this.opt2}`
      this.current_condition = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      getLectureList(params).then(_resp => {
        this.items = _resp.data.list
       this.selectedCnt = this.items.length
      }) 
    },
    updateOpt3(data) {
      if(data.id < 0) return
      this.opt3 = data.id
      var params = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}`
      this.current_condition = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      getLectureList(params).then(_resp => {
        this.items = _resp.data.list
        this.items.forEach((value, index) => {
          if (this.items.play_order !== value.play_order) {
            this.next_index_list.push({ value: index, text: index })
     
          }
        })
        this.selectedCnt = this.items.length
      })
      
    },
    updateLcNo(data) {
      this.opt4 = data
      var params = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      this.current_condition = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      getLectureList(params).then(_resp => {
        this.items = _resp.data.list
        this.selectedCnt = this.items.length
      })
    },
    search() {},
    async removeLecture() {
      try {
        const resp = await deleteLecture(this.selected_lc_no)
        if (resp.code == '0000') {
          alert('삭제되었습니다.')
          this.closeModal('modal-delete')
          this.items = []
          getLectureList(this.current_condition).then(_resp => {
            this.items = _resp.data.list
          })
        }
      } catch (error) {
        console.error('removeLecture', error)
      }
      //const resp = await getLectureInfo(item.lc_no)
      //console.log(resp.data)
    }
  },
  async created() {
    getLectureList(this.current_condition).then(_resp => {
      this.items = _resp.data.list
      this.selectedCnt = this.items.length
      // this.vKey = _resp.data.vKey
    })
  },
  mounted() {}
}
</script>
